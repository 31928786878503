import { graphql } from "gatsby";
import TransitionLink from "gatsby-plugin-transition-link";
import { Power2, TimelineLite, TweenLite } from "gsap/all";
// NEEDED FOR GSAP TO WORK ON PRODUCTION
import CSSPlugin from "gsap/CSSPlugin";
const C = CSSPlugin; // eslint-disable-line
import React, { useEffect, useRef, useState } from "react";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ReactMarkdown from "react-markdown/with-html";

import SEO from "../components/seo";
import { onInitPageLoadTracking, sendGA } from "../helpers/tracking";
import { ILinkedJob } from "../interfaces";
import CustomEase from "../vendors/CustomEase";

const Template = ({ data, pathContext: { shareUrl } }: { data: any; pathContext: any }) => {
  CustomEase.create("bouceStart", "M0,0 C0.096,-0.136 0.242,-0.208 0.326,-0.07 0.508,0.231 0.651,1 1,1");

  // STATES
  const [duration] = useState<number>(0.4);

  // REFS
  const $page = useRef(null);
  const $bgLogo = useRef(null);
  const $title = useRef(null);
  const $popinCta = useRef(null);
  const $popinContent = useRef(null);
  const $jobShare = useRef(null);
  const $subPartMission = useRef(null);
  const $subPartKnow = useRef(null);
  const $subPartEvo = useRef(null);
  const $subPartLinks = useRef(null);

  // VARS
  const { jobDescriptionJson } = data; // data.jobDescriptionJson holds our post data
  const { titleGa, content } = jobDescriptionJson;
  const { title, description, connaissances, evolution, linked_job } = content;

  useEffect(() => {
    enterAnimation();
    onInitPageLoadTracking();
  }, []);

  const enterAnimation = () => {
    const titleNode = $title.current;
    const ctaNode = $popinCta.current;
    const jobShareNode = $jobShare.current;
    const subPartMissionNode = $subPartMission.current;
    const subPartKnowNode = $subPartKnow.current;
    const subPartEvoNode = $subPartEvo.current;
    const subPartLinksNode = $subPartLinks.current;
    let subPartsNode: any[] = [];

    if (subPartMissionNode) {
      subPartsNode = subPartsNode.concat([subPartMissionNode]);
    }
    if (subPartKnowNode) {
      subPartsNode = subPartsNode.concat([subPartKnowNode]);
    }
    if (subPartEvoNode) {
      subPartsNode = subPartsNode.concat([subPartEvoNode]);
    }
    if (subPartLinksNode) {
      subPartsNode = subPartsNode.concat([subPartLinksNode]);
    }

    const tl = new TimelineLite({
      delay: 0.3,
    });

    tl.set([titleNode, subPartsNode, jobShareNode], { alpha: 0 })
      .to($page.current, 0.1, { alpha: 1 })
      .fromTo(titleNode, 0.6, { y: -10 }, { alpha: 1, y: 0, ease: Power2.easeInOut })
      .staggerFromTo(subPartsNode, 0.6, { y: -10 }, { alpha: 1, y: 0, ease: Power2.easeInOut }, 0.1, "-=0.4")
      .to(jobShareNode, 0.3, { alpha: 1, ease: Power2.easeInOut }, "-=0.2")
      .to(ctaNode, 0.8, { x: 0, ease: Power2.easeInOut });
  };

  const exitAnimation = (_exit: any, node: any) => {
    TweenLite.to(node, duration, { alpha: 0 });
  };

  const onClickPopinCta = () => {
    sendGA("click", {
      event_label: "LB - Pour aller plus loin",
    });

    openPopin();
  };

  const onClickPopinClose = () => {
    closePopin();
  };

  const openPopin = () => {
    const x = $popinCta.current.offsetWidth;
    const tl = new TimelineLite();

    tl.to($popinCta.current, 0.6, { className: "+=popin-open", ease: "bouceStart", x }).to(
      $popinContent.current,
      0.8,
      { ease: Power2.easeInOut, x: 0 },
      "-=0.4",
    );
  };

  const closePopin = () => {
    const x = $popinContent.current.offsetWidth;

    const tl = new TimelineLite({
      onComplete: () => {
        TweenLite.set($popinCta.current, { className: "-=popin-open" });
      },
    });

    tl.to($popinContent.current, 0.6, { ease: "bouceStart", x }).to($popinCta.current, 0.4, {
      ease: Power2.easeOut,
      x: 0,
    });
  };

  const getConnaissancesList = (points: string[]) =>
    points.map((point: string, i: number) => {
      return (
        <li key={i} className="point-item">
          <ReactMarkdown escapeHtml={false} source={point} />
        </li>
      );
    });

  const getJobsList = (jobs: ILinkedJob[]) =>
    jobs.map((job: ILinkedJob, i: number) => {
      return (
        <li key={i} className="job-item">
          <TransitionLink
            to={job.path}
            exit={{
              length: duration,
              trigger: ({ exit, node }: { exit: any; node: any }) => exitAnimation(exit, node),
            }}
            entry={{
              delay: duration,
            }}
            className="job-item-link underlined-link"
            onClick={() =>
              sendGA("click", {
                event_label: `LB - Métier - ${job.name}`,
              })
            }
          >
            {job.name}
          </TransitionLink>
        </li>
      );
    });

  return (
    <>
      <SEO
        title={titleGa}
        description="Objectifs, missions, perspectives d’évolutions, etc. : découvrez ce qui se cache derrière cet intitulé de poste. "
        meta={{
          "og:image": "https://elinoi-uploads.s3.eu-west-3.amazonaws.com/visuel-partage-fiche-me%CC%81tiers.jpg",
          "og:image:height": "566",
          "og:image:secure_url":
            "https://elinoi-uploads.s3.eu-west-3.amazonaws.com/visuel-partage-fiche-me%CC%81tiers.jpg",
          "og:image:width": "1080",
          "og:url": shareUrl,
        }}
      />
      <div ref={$page} className="job-page-container page-container container" style={{ opacity: 0 }}>
        <div ref={$title} className="title-container">
          <ReactMarkdown className="job-title title-text" escapeHtml={false} source={title} />
        </div>
        <div className="job-content small-container">
          <div ref={$subPartMission} className="content-subpart">
            <div className="subpart-title-container">
              <h2 className="subpart-title">Les missions :</h2>
            </div>
            <div className="subpart-text-container">
              <ReactMarkdown className="subpart-text text" escapeHtml={false} source={description} />
            </div>
          </div>

          <div ref={$subPartKnow} className="content-subpart">
            <div className="subpart-title-container">
              <h2 className="subpart-title">Ce métier est fait pour vous si :</h2>
            </div>
            <div className="subpart-text-container">
              <div className="subpart-text text upper">
                <ul>{getConnaissancesList(connaissances)}</ul>
              </div>
            </div>
          </div>

          {evolution.length ? (
            <div ref={$subPartEvo} className="content-subpart">
              <div className="subpart-title-container">
                <h2 className="subpart-title">Ce job vous permet d’évoluer sur un poste de :</h2>
              </div>
              <div className="subpart-text-container">
                <ReactMarkdown className="subpart-text text" escapeHtml={false} source={evolution} />
              </div>
            </div>
          ) : null}
          {linked_job.length ? (
            <div ref={$subPartLinks} className="content-subpart">
              <div className="subpart-title-container">
                <h2 className="subpart-title">Ces métiers pourraient aussi vous intéresser :</h2>
              </div>

              <div className="subpart-text-container">
                <div className="subpart-text text upper">
                  <ul className="linked-jobs-list">{getJobsList(linked_job)}</ul>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div ref={$jobShare} className="job-sharing-container small-container">
          <div className="job-sharing-content">
            <div>
              <span className="text sharing-cta">Partager cette fiche :</span>
              <div className="sharing-options-container">
                <ul className="sharing-list">
                  <li className="sharing-item">
                    <a
                      className="facebook"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
                      onClick={() =>
                        sendGA("click", {
                          event_label: `LB - Partage - ${titleGa} - Facebook`,
                        })
                      }
                    >
                      <span>
                        <FaFacebookF />
                      </span>
                    </a>
                  </li>
                  <li className="sharing-item">
                    <a
                      className="linkedin"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`}
                      onClick={() =>
                        sendGA("click", {
                          event_label: `LB - Partage - ${titleGa} - LinkedIn`,
                        })
                      }
                    >
                      <span>
                        <FaLinkedinIn />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="singup-container">
              <span className="text sharing-cta">
                Ce métier vous intéresse et <br /> vous souhaitez aller plus loin ?
              </span>
              <a
                className="text singup-link underlined-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://elinoi.com/?utm_source=livre%20blanc&utm_medium=ref&utm_campaign=inscrire_sur_elinoi"
                onClick={() =>
                  sendGA("click", {
                    event_label: "LB - Fiche métier - Page talent",
                  })
                }
              >
                Inscrivez-vous sur elinoï
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="popin-cta-wrapper">
        <div className="popin-cta-container">
          <div
            ref={$popinCta}
            className="popin-cta"
            onClick={() => onClickPopinCta()}
            style={{ transform: "translateX(100%)" }}
          >
            <div className="popin-cta-text popin-justified-text">
              <span className="pour">Pour</span>
              <span>aller</span>
              <span className="plus">plus</span>
              <span className="loin">loin</span>
            </div>
          </div>
        </div>
      </div>

      <div className="popin-wrapper">
        <div className="popin-container">
          <div ref={$popinContent} className="popin-content">
            <div className="popin-inner-content">
              <span className="popin-close" onClick={() => onClickPopinClose()}></span>
              <div className="popin-justified-text">
                <span className="pour">Pour</span>
                <span>aller</span>
                <span className="plus">plus</span>
                <span className="loin">loin</span>
              </div>
              <div className="popin-content-text">
                Vous avez envie d'en savoir plus et de découvrir de nouvelles opportunités ? Inscrivez-vous sur elinoï
                et rejoignez notre incubateur.
              </div>
              <div className="popin-singup-link-container">
                <a
                  className="popin-singup-link underlined-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://elinoi.com/?utm_source=livre%20blanc&utm_medium=ref&utm_campaign=pour_aller_plus_loin"
                  onClick={() =>
                    sendGA("click", {
                      event_label: "LB - Pour aller plus loin - Page talent",
                    })
                  }
                >
                  S'inscrire
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="start-again">
        <TransitionLink
          className="underlined-link"
          onClick={() =>
            sendGA("click", {
              event_label: "LB - Footer - Recommencer",
            })
          }
          to="/"
          exit={{
            length: duration,
            trigger: ({ exit, node }: { exit: any; node: any }) => exitAnimation(exit, node),
          }}
          entry={{
            delay: duration,
          }}
        >
          Recommencer
        </TransitionLink>
      </div>
    </>
  );
};

export default Template;

export const pageQuery = graphql`
  query($path: String!) {
    jobDescriptionJson(path: { eq: $path }) {
      path
      titleGa
      content {
        title
        description
        connaissances
        evolution
        linked_job {
          path
          name
        }
      }
    }
  }
`;
